import React, {useEffect} from "react";
import {issignin} from "../../modules/isSignIn";
import axios from "axios";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux"; // import { useNavigate } from 'react-router';

const CallbackPage = ({setIsLogin, setIsUserLogin}: any) => {
  // const navigate = useNavigate()

  const url = new URL(window.location.href);
  const history: any = useHistory();
  const dispatch = useDispatch();
  const searchs = url.search;

  useEffect(() => {
    if (url.pathname === "/callback/kakao") {
      const code: string | null = searchs.split("=")[1].split("&")[0];

      fetch(`${process.env.REACT_APP_API_URI}/auth/kakao`, {
        credentials: "include",
        method: "post",
        body: JSON.stringify({code: code}),

        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res: any) => {
          if (!res.ok) {
            history("/login");

            setIsLogin(false);
            dispatch(issignin());
            throw new Error(res.status);
          }

          return res.json();
        })
        .then(data => {
          localStorage.setItem("accessToken", data.data.accessToken);

          history.push("/");
          setIsLogin(true);
          dispatch(issignin());
        })
        .catch(err => {
          setIsLogin(false);
        });
    } else if (url.pathname === "/callback/google") {
      const code: string | null = searchs.split("=")[1].split("&")[0];

      fetch(`${process.env.REACT_APP_API_URI}/auth/google`, {
        credentials: "include",
        method: "post",
        body: JSON.stringify({code: code}),

        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res: any) => {
          if (!res.ok) {
            history("/login");
            setIsLogin(false);

            throw new Error(res.status);
          }
          return res.json();
        })
        .then(data => {
          localStorage.setItem("accessToken", data.data.accessToken);

          history.push("/");
          setIsLogin(true);
          dispatch(issignin());
        })
        .catch(err => {
          setIsLogin(false);
        });
    }
  }, []);

  return <></>;
};

export default CallbackPage;
