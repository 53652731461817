import {createAction, handleActions} from "redux-actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


const ISSIGNIN = "mailWriteText/ISSIGNIN";
const ISSIGNOUT = "mailWriteText/ISSIGNOUT";

export const issignin = createAction(ISSIGNIN);
export const issignout = createAction(ISSIGNOUT);


const persistConfig = {
  key: "signin",
  // localStorage에 저장합니다.
  storage,
  // auth, board, studio 3개의 reducer 중에 auth reducer만 localstorage에 저장합니다.
  whitelist: ["auth"]
  // blacklist -> 그것만 제외합니다
};

const initialState = {
  isSign: false,
};

const mailWriteText = handleActions(
  {
    [ISSIGNIN]: (state: any, isSign: any) => ({isSign: true}),
    [ISSIGNOUT]: (state: any, isSign: any) => ({isSign: false}),
  },
  initialState,
);

export default mailWriteText;
